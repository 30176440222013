<template>
  <label class="checkbox" :class="{ checkbox_checked: isChecked }">
    <div
      class="checkbox__back-wrap"
      :class="{ 'checkbox__back-wrap_checked': isChecked }"
    >
      <div
        class="checkbox__wrap"
        :class="{ checkbox__wrap_checked: isChecked }"
      >
        <svg
          v-show="isChecked"
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5196 0C10.1238 0 9.75184 0.162293 9.47191 0.456982L3.98971 6.23613L2.52856 4.69847C2.24881 4.40398 1.87686 4.24176 1.48122 4.24176C1.08552 4.24176 0.713561 4.40398 0.433753 4.6984C0.154011 4.99282 0 5.38428 0 5.80067C0 6.21706 0.154011 6.60853 0.433753 6.90288L2.94257 9.54329C3.22231 9.83778 3.59427 10 3.98997 10C4.38548 10 4.75757 9.83771 5.03763 9.54302L11.567 2.66132C12.1444 2.05342 12.1443 1.06441 11.567 0.456505C11.2872 0.162157 10.9152 0 10.5196 0Z"
            fill="#1F1F1F"
          />
        </svg>

        <input
          type="checkbox"
          :id="`checkbox_${_uid}`"
          v-model="inputVal"
          :value="inputValue"
          @input="$emit('change')"
          :disabled="disabled"
        />
      </div>
    </div>
    <div class="checkbox__text">
      <div
        :for="`checkbox_${_uid}`"
        class="checkbox__label"
        v-html="label"
      ></div>
    </div>
  </label>
</template>

<script>
export default {
  name: "Checkbox",
  props: {
    value: [Boolean, Array, Number, String],
    label: String,
    inputValue: [String, Number],
    error: Boolean,
    disabled: Boolean,
  },
  data: () => ({
    errorText: "",
    isError: false,
  }),
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isChecked() {
      return this.inputVal.includes(this.inputValue);
    },
  },

  watch: {
    inputVal() {},
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  padding: 16px 24px 16px 0;
  background-color: #fff;
  border: 1px solid #d2d2d2;
  border-radius: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    width: calc(100% - 10px);
    margin-left: auto;
  }

  &__back-wrap {
    margin-left: -16px;
    width: 30px;
    height: 30px;
    border-right: 1px solid #d2d2d2;
    border-radius: 10px;
    background-color: #f8f8f8;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    &_checked {
      border-color: #f8f8f8;
    }
  }

  &__wrap {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    border: 2px solid #1f1f1f;
    border-radius: 5px;
    flex-shrink: 0;
    background-color: #fff;
    color: #830051;

    &_checked {
      // border-color: #830051;
    }

    & input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1000;
      margin: 0;
      padding: 0;
      cursor: pointer;
    }
  }

  &__text {
    margin-left: 24px;
  }

  &__label {
    margin-top: 1px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #1f1f1f;
  }

  &_checked {
    // background-color: #830051;
    // border-color: #830051;
    // box-shadow: 0px 20px 35px -20px #00000099;
    // & .checkbox {
    //   &__label {
    //     color: #fff;
    //   }
    // }
  }
}
</style>

<style lang="scss" >
.checkbox {
  &__label {
    & b {
      font-weight: 700;
    }
  }
}
</style>